import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Badge, Button } from '@protonradio/proton-ui';
import Avatar from 'components/Avatar';
import Modal from 'components/Modal';
import TextHeader from 'components/TextHeader';

import { COLORS } from 'config/constants';
import { ToggleDiscoveryModeMutation } from 'graphql/mutations/discoveryMode';
import useMutationWithAlert from 'hooks/graphql/useMutationWithAlert';

import { OptInProps } from './OptInModal';
import { StyledModal, StyledTextHeader, StyledLink } from '../styles';

const OptInSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

const OptInSelectionItem = styled.label<{
  $isDisabled?: boolean;
  $isSelected?: boolean;
  $previouslyOptedIn?: boolean;
}>`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  gap: 16px;
  border-radius: 8px;
  cursor: ${props =>
    props.$previouslyOptedIn || props.$isDisabled ? 'not-allowed' : 'pointer'};
  opacity: ${props => (props.$previouslyOptedIn || props.$isDisabled ? 0.6 : 1)};
  border: 1px solid ${props => (props.$isSelected ? COLORS.PRIMARY : COLORS.BORDER)};
  background-color: ${props =>
    props.$isSelected ? `${COLORS.PRIMARY}10` : 'transparent'};

  > :nth-child(1) {
    flex: 0 0 auto;
    outline: 2px solid ${props => (props.$isSelected ? COLORS.PRIMARY : 'transparent')};
    transition: outline 0.25s ease;
  }
  > :nth-child(2) {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    gap: 16px;
  }
  > :nth-child(3) {
    flex: 0 0 auto;
  }

  transition:
    background-color,
    border-color 0.25s ease;
`;

const StyledCheckbox = styled.input`
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid ${COLORS.BORDER};
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  transition:
    background-color 0.25s ease,
    border-color 0.25s ease;

  &:checked {
    background-color: ${COLORS.PRIMARY};
    border-color: ${COLORS.PRIMARY};
  }

  // check mark
  &:checked::after {
    content: '';
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
  }
`;

type SelectedEntity = {
  id: string;
  name: string;
  isSelected: boolean;
};

export const SelectEntities = ({ viewer, modalLocation }: OptInProps) => {
  const history = useHistory();
  const [selectedEntity, setSelectedEntity] = useState<SelectedEntity[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [, executeMutation] = useMutationWithAlert(ToggleDiscoveryModeMutation);

  //check for feature flag and isable non-flagged artists?

  const optInEntities = async () => {
    setLoading(true);
    try {
      const results = await Promise.all(
        selectedEntity.map(selectedEntity =>
          executeMutation({
            artistId: selectedEntity.id,
            enabled: true
          })
        )
      );

      if (results.some(result => result.hasErrors)) {
        setLoading(false);
        return;
      }

      history.push({
        pathname: '/dm-opt-in/success',
        state: { modal: true, modalLocation }
      });
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Modal onClose={() => history.push(modalLocation)} title="Opt In Selection">
      <StyledModal>
        <form
          style={{ width: '95%' }}
          onSubmit={e => {
            e.preventDefault();
            optInEntities();
          }}
        >
          <TextHeader type={TextHeader.TYPES.XSMALL}>Artists</TextHeader>
          <OptInSelectionContainer>
            {viewer?.artists.map(artist => {
              const isFeatureFlagEnabled = artist.configs?.discoveryModeFeatureEnabled;
              const isPreviouslyOptedIn = artist.configs?.hasDiscoveryModeEnabled;
              const isSelected = selectedEntity.some(
                selected => selected.id === artist.id
              );
              return (
                <OptInSelectionItem
                  key={artist.id}
                  $isDisabled={!isFeatureFlagEnabled}
                  $isSelected={isSelected || isPreviouslyOptedIn}
                  $previouslyOptedIn={isPreviouslyOptedIn}
                >
                  <Avatar
                    circle
                    size={Avatar.SIZES.LARGE}
                    imageUrl={artist.image?.url || ''}
                    altTag={artist.name || ''}
                  />

                  <div>
                    <StyledTextHeader type={TextHeader.TYPES.LARGE}>
                      {artist.name}
                    </StyledTextHeader>

                    {isPreviouslyOptedIn && <Badge variant="success">Opted in</Badge>}
                  </div>

                  <StyledCheckbox
                    type="checkbox"
                    name="artist"
                    value={artist.id}
                    checked={isSelected || isPreviouslyOptedIn}
                    onChange={e => {
                      const isChecked = e.target.checked;
                      setSelectedEntity(prev => {
                        if (isChecked) {
                          return [
                            ...prev,
                            {
                              id: artist.id,
                              name: artist.name || '',
                              isSelected: true
                            }
                          ];
                        }
                        return prev.filter(selected => selected.id !== artist.id);
                      });
                    }}
                  />
                </OptInSelectionItem>
              );
            })}
          </OptInSelectionContainer>
          <Modal.ButtonGroup justify="center">
            <Button variant="secondary">
              <StyledLink
                to={{
                  pathname: 'dm-opt-in',
                  state: {
                    modalLocation,
                    modal: true
                  }
                }}
              >
                Back
              </StyledLink>
            </Button>
            <Button
              type="submit"
              variant="primary"
              onPress={() => optInEntities()}
              isDisabled={selectedEntity.length === 0 || loading}
            >
              {loading ? `Opting in...` : optInButtonText(selectedEntity)}
            </Button>
          </Modal.ButtonGroup>
        </form>
      </StyledModal>
    </Modal>
  );
};

const optInButtonText = (selectedEntity: SelectedEntity[]) =>
  selectedEntity.length === 0
    ? 'Opt in'
    : `Opt in ${selectedEntity.length} ${
        selectedEntity.length === 1 ? 'artist' : 'artists'
      }`;
