import React from 'react';
import { Table, TableHeader, Column, TableBody, Row, Cell } from '@protonradio/proton-ui';
import CommaList from 'components/CommaList';
import Flex from 'components/Flex';
import Icon from 'components/Icon';
import { useDiscoveryMode } from './DiscoveryModeContext';
import { TablePlaceholder, MobileRowWrapper, StyledMobileTableHeader } from './styles';
import useBreakpoint from 'hooks/useBreakpoint';
import { EligibleTracksFieldsFragment } from 'gql/graphql';
import { ConditionalDash, TrackCellData } from './TableComponents';
import styled from 'styled-components';

interface EnabledTracksTableProps {
  tracklist: EligibleTracksFieldsFragment[];
}

const DesktopEnabledTracksTable = ({ tracklist }: EnabledTracksTableProps) => {
  return (
    <Table>
      <TableHeader>
        <Column>Track</Column>
        <Column>Label</Column>
        <Column>Remix Artists</Column>
        <Column>Intent Rate</Column>
        <Column>Auto Renew</Column>
      </TableHeader>
      <TableBody>
        {tracklist?.map((rowData, index) => {
          const performance = rowData;
          const track = performance.track;

          return (
            <Row key={index + 1} data-testid="row">
              <Cell>
                <TrackCellData trackCampaignData={rowData} />
              </Cell>
              <Cell>{track.release?.label?.name}</Cell>
              <Cell>
                <ConditionalDash condition={Boolean(track.remixArtists.length)}>
                  <CommaList data={track.remixArtists.map(a => a.name)} />
                </ConditionalDash>
              </Cell>
              <Cell>
                <ConditionalDash condition={Boolean(performance.intentRate)}>
                  <Flex align="center">
                    {performance.intentRate}%
                    <Icon
                      type={Icon.TYPES.TROPHY}
                      color={'gold'}
                      width={16}
                      className="ml-1"
                    />
                  </Flex>
                </ConditionalDash>
              </Cell>
              <Cell>
                <ConditionalDash
                  condition={Boolean(
                    performance.intentRate && performance.intentRate > 1
                  )}
                >
                  <Icon
                    type={Icon.TYPES.CHECK_MARK}
                    color={Icon.COLORS.PRIMARY}
                    width={16}
                  />
                </ConditionalDash>
              </Cell>
            </Row>
          );
        })}
      </TableBody>
    </Table>
  );
};

const IntentRateRow = styled.div`
  width: 64px;
  display: flex;
  justify-content: center;
`;

const MobileEnabledTracksTable = ({ tracklist }: EnabledTracksTableProps) => (
  <>
    <StyledMobileTableHeader className="proton-Table__header">
      Intent Rate
    </StyledMobileTableHeader>
    {tracklist.map((rowData, index) => (
      <MobileRowWrapper key={index}>
        <TrackCellData trackCampaignData={rowData} />
        <IntentRateRow>
          <ConditionalDash condition={!rowData.intentRate}>
            <>
              {rowData.intentRate}%
              <Icon type={Icon.TYPES.TROPHY} color={'gold'} width={16} className="ml-1" />
            </>
          </ConditionalDash>
        </IntentRateRow>
      </MobileRowWrapper>
    ))}
  </>
);

const EnabledTracks = () => {
  const { enabledTracklist: tracklist } = useDiscoveryMode();
  const enabledTracklist = tracklist?.filter(track => track.enabled);

  const isSmallScreen = useBreakpoint(
    useBreakpoint.BREAKPOINTS.SMALL,
    useBreakpoint.DIRECTIONS.DOWN
  );

  if (!tracklist || !enabledTracklist || enabledTracklist.length === 0) {
    return (
      <div className="mt-2" data-testid="DiscoveryMode-Enabled-Table">
        <TablePlaceholder>No tracks found</TablePlaceholder>
      </div>
    );
  }

  return (
    <div data-testid="DiscoveryMode-Enabled-Table">
      {!isSmallScreen ? (
        <DesktopEnabledTracksTable tracklist={enabledTracklist} />
      ) : (
        <MobileEnabledTracksTable tracklist={enabledTracklist} />
      )}
    </div>
  );
};

export default EnabledTracks;
