import React from 'react';

import CountdownTimer from './CountdownTimer';
import {
  Banner,
  Cell,
  Column,
  Row,
  Table,
  TableBody,
  TableHeader
} from '@protonradio/proton-ui';

import {
  DiscoveryModeCampaignStateEnum,
  EligibleTracksFieldsFragment
} from 'gql/graphql';
import { useDiscoveryMode } from './DiscoveryModeContext';
import CommaList from 'components/CommaList';
import Flex from 'components/Flex';
import { MobileRowWrapper, StyledMobileTableHeader, TablePlaceholder } from './styles';
import Icon from 'components/Icon';
import useBreakpoint from 'hooks/useBreakpoint';
import { ConditionalDash, TrackCellData, TrackScheduleButton } from './TableComponents';

interface EligibleTracksTableProps {
  tracklist: EligibleTracksFieldsFragment[];
  campaign: {
    title: string;
    id: string;
  };
}

const DesktopEligibleTracksTable = ({
  tracklist,
  campaign
}: EligibleTracksTableProps) => {
  const { toggleTrack } = useDiscoveryMode().scheduledQueue;
  return (
    <Table>
      <TableHeader>
        <Column>Track</Column>
        <Column>Label</Column>
        <Column>Remix Artists</Column>
        <Column>Intent Rate</Column>
        <Column>Scheduled for {campaign.title}</Column>
        <Column>{''}</Column>
      </TableHeader>
      <TableBody>
        {tracklist?.map((rowData, index) => {
          const performance = rowData;
          const track = performance.track;

          return (
            <Row key={index + 1} data-testid="row">
              <Cell>
                <TrackCellData trackCampaignData={rowData} />
              </Cell>
              <Cell>{track.release?.label?.name}</Cell>
              <Cell>
                <ConditionalDash condition={Boolean(track.remixArtists.length)}>
                  <CommaList data={track.remixArtists.map(a => a.name)} />
                </ConditionalDash>
              </Cell>
              <Cell>
                <ConditionalDash condition={Boolean(performance.intentRate)}>
                  <Flex align="center">
                    {performance.intentRate}%
                    <Icon
                      type={Icon.TYPES.TROPHY}
                      color={'gold'}
                      width={16}
                      className="ml-1"
                    />
                  </Flex>
                </ConditionalDash>
              </Cell>
              <Cell>
                <ConditionalDash condition={Boolean(performance.enabled)}>
                  <Icon
                    type={Icon.TYPES.CHECK_MARK}
                    color={Icon.COLORS.PRIMARY}
                    width={16}
                  />
                </ConditionalDash>
              </Cell>

              <Cell>
                <Flex justify="flex-end">
                  <Flex justify="flex-end" style={{ maxWidth: '100px' }}>
                    <TrackScheduleButton
                      trackCampaignData={rowData}
                      onPress={() =>
                        toggleTrack({
                          campaignId: campaign.id,
                          trackId: track.id,
                          enabled: performance.enabled,
                          artistId: track.originalArtists[0].id
                        })
                      }
                    />
                  </Flex>
                </Flex>
              </Cell>
            </Row>
          );
        })}
      </TableBody>
    </Table>
  );
};

const MobileEligibleTracksTable = ({ tracklist, campaign }: EligibleTracksTableProps) => {
  const { toggleTrack } = useDiscoveryMode().scheduledQueue;
  return (
    <>
      <StyledMobileTableHeader className="proton-Table__header">
        Scheduled for {campaign.title}
      </StyledMobileTableHeader>
      {tracklist.map((rowData, index) => (
        <MobileRowWrapper key={index}>
          <TrackCellData trackCampaignData={rowData} />
          <TrackScheduleButton
            trackCampaignData={rowData}
            onPress={() => {
              toggleTrack({
                campaignId: campaign.id,
                trackId: rowData.track.id,
                enabled: rowData.enabled,
                artistId: rowData.track.originalArtists[0].id
              });
            }}
          />
        </MobileRowWrapper>
      ))}
    </>
  );
};

const EligibleTracksTable = () => {
  const { eligibleTracklist: tracklist, discoveryMode } = useDiscoveryMode();

  const isSmallScreen = useBreakpoint(
    useBreakpoint.BREAKPOINTS.SMALL,
    useBreakpoint.DIRECTIONS.DOWN
  );

  if (!tracklist || tracklist?.length === 0) {
    return (
      <div className="mt-2" data-testid="DiscoveryMode-Eligible-Table">
        <TablePlaceholder>No tracks found</TablePlaceholder>
      </div>
    );
  }

  const tableProps: EligibleTracksTableProps = {
    tracklist,
    campaign: {
      title: discoveryMode?.upcomingCampaign?.title || '',
      id: discoveryMode?.upcomingCampaign?.id || ''
    }
  };

  return !isSmallScreen ? (
    <DesktopEligibleTracksTable {...tableProps} />
  ) : (
    <MobileEligibleTracksTable {...tableProps} />
  );
};

const EligibleTracks = () => {
  const { discoveryMode } = useDiscoveryMode();
  const upcomingCampaignTitle = discoveryMode?.upcomingCampaign?.title.split(' ')[0];
  const runningCampaignTitle = discoveryMode?.runningCampaign?.title.split(' ')[0];
  const isBeforeScheduling =
    discoveryMode?.upcomingCampaign?.state ===
    DiscoveryModeCampaignStateEnum.BeforeScheduling;

  return (
    <>
      {isBeforeScheduling ? (
        <>
          <div className="mt-2 mb-2">
            <Banner variant="warning" icon data-testid="DiscoveryMode-Banner-Warning">
              <Banner.Title>
                Submission deadline for {runningCampaignTitle} has passed!
              </Banner.Title>
            </Banner>
          </div>
          <CountdownTimer
            title={upcomingCampaignTitle}
            schedulingStartsAt={new Date(discoveryMode?.nextSchedulingStartsAt)}
          />
        </>
      ) : (
        <EligibleTracksTable data-testid="DiscoveryMode-Eligible-Table" />
      )}
    </>
  );
};

export default EligibleTracks;
