import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Modal from 'components/Modal';
import TextHeader from 'components/TextHeader';
import { TextEmphasis, Button } from '@protonradio/proton-ui';

import { ToggleDiscoveryModeMutation } from 'graphql/mutations/discoveryMode';
import useMutationWithAlert from 'hooks/graphql/useMutationWithAlert';

import { intercomPaths } from 'routes/DiscoveryMode/DiscoveryMode';
import { OptInProps } from './OptInModal';
import { StyledTooltipContent, StyledTextHeader, StyledLink } from '../styles';

export const OnboardingStep = ({ modalLocation, entityId }: OptInProps) => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [, executeMutation] = useMutationWithAlert(ToggleDiscoveryModeMutation);

  const handleContinue = useCallback(async () => {
    if (entityId) {
      setLoading(true);
      try {
        const result = await executeMutation({ artistId: entityId, enabled: true });

        if (result.hasErrors) throw new Error('Something went wrong');

        history.push({
          pathname: `/dm-opt-in/success`,
          state: { modal: true, modalLocation, entityId }
        });
      } catch (error) {
        history.push(modalLocation);
      } finally {
        setLoading(false);
      }
    } else {
      history.push({
        pathname: '/dm-opt-in/select',
        state: { modal: true, modalLocation }
      });
    }
  }, [executeMutation, entityId, modalLocation, history]);

  return (
    <Modal onClose={() => history.push(modalLocation)} title="Opt In to Discovery Mode?">
      <>
        <p>
          Opting in will allow you and your{' '}
          <TextEmphasis
            tooltipProps={{
              arrow: true,
              content: (
                <StyledTooltipContent>
                  You, your featured artists, and remix artists, if any.
                </StyledTooltipContent>
              )
            }}
          >
            collaborators
          </TextEmphasis>{' '}
          add{' '}
          <TextEmphasis
            tooltipProps={{
              arrow: true,
              content: (
                <StyledTooltipContent>
                  A track is eligible when it has been out for at least 1 month, it has at
                  least one play in Spotify Radio or Autoplay in the last month, and all
                  the tracks collaborators have opted in.
                </StyledTooltipContent>
              )
            }}
          >
            eligible tracks
          </TextEmphasis>{' '}
          to your monthly Discovery Mode campaigns. If a collaborator isn't opted in,
          we'll help you invite them!
        </p>
      </>

      <div className="mt-1" />

      <p>
        While opted in, collaborators can enable Discovery Mode on any tracks you're a
        part of, you cannot block collaborators from enabling specific tracks. To block
        collaborators from enabling your tracks, don't opt in.
      </p>

      <div className="mt-2" />

      <StyledTextHeader type={TextHeader.TYPES.MEDIUM}>
        Discovery Mode Costs
      </StyledTextHeader>
      <p>
        Spotify charges a 30% earnings deduction on plays from{' '}
        <TextEmphasis
          tooltipProps={{
            arrow: true,
            content: (
              <StyledTooltipContent>
                Spotify Radio, Autoplay, Daily Mixes, Artist Mixes, Mood Mixes, Decade
                Mixes, and Genre Mixes
              </StyledTooltipContent>
            )
          }}
        >
          participating playlists
        </TextEmphasis>{' '}
        which is shared between Proton, the label and the artists based on the tracks
        royalty percentage.
      </p>

      <div className="mt-1" />

      <p>Change your mind? You can opt out at any time.</p>

      <div className="mt-1" />

      <Modal.ButtonGroup justify="center">
        <Button variant="secondary" isDisabled={loading}>
          <StyledLink to={intercomPaths.placeholder}>Learn More</StyledLink>
        </Button>

        <Button
          onPress={() => handleContinue()}
          isDisabled={loading}
          data-testid="OptIn-Button"
        >
          {loading ? 'Opting in...' : 'Continue'}
        </Button>
      </Modal.ButtonGroup>
    </Modal>
  );
};
