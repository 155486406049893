import React, { useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { NavTab, NavTabContainer, NavTabGroup } from 'components/Nav';
import TabbedHeaderLayout from 'components/TabbedHeaderLayout';
import { RouteNotFound } from 'routes/ErrorPage';

import { Banner, Button, Badge, Popover } from '@protonradio/proton-ui';
import SpotifyLogo from 'components/Icons/SpotifyLogo';
import { GradientGreenBackground, LogoTitleWrapper } from './styles';

import routeMap from 'config/routes';
import { useDiscoveryMode } from './DiscoveryModeContext';
import { TableTombstones } from './TableComponents';
import styled from 'styled-components';
import EligibleTracks from './EligibleTracks';
import EnabledTracks from './EnabledTracks';
import { useDispatch } from 'react-redux';
import { showAlert } from 'redux/actions/ui';
import { useAppDispatch } from 'hooks/redux';
import { updateIntercomPosition } from 'config/intercom';

const HeaderAction = styled.span`
  font-size: 1.4rem;
  height: 2rem;
  display: flex;
  align-items: center;
`;

const NavTabDisabled = styled.span`
  margin-left: 8px;
  opacity: 0.5;
  pointer-events: none;
`;

const LogoTitle = () => (
  <LogoTitleWrapper>
    <SpotifyLogo />
    <span>Discovery Mode</span>
  </LogoTitleWrapper>
);

const StyledBadge = ({
  loading,
  children
}: {
  loading?: boolean;
  children?: React.ReactNode;
}) => {
  if (loading) return null;

  return <Badge variant="secondary">{children}</Badge>;
};

const paths = {
  eligible: `/discovery-mode/eligible`,
  scheduled: `/discovery-mode/scheduled`,
  enabled: `/discovery-mode/enabled`,
  insights: `/discovery-mode/insights`,
  default: `/discovery-mode/eligible`
};
export type DiscoveryModePaths = keyof Omit<typeof paths, 'default'>;

export const intercomPaths = {
  placeholder: 'https://www.intercom.com/'
};

export const settingsPaths = {
  notificationSettings: '/account/notifications#discovery-mode',
  optInSettings: '/account/discovery-mode'
};

const DiscoveryMode = () => {
  const { loading, error, eligibleTracklist, enabledTracklist } = useDiscoveryMode();
  const location = useLocation();
  const dispatch = useAppDispatch();

  if (location.search.includes('optedIn=true')) {
    dispatch(
      showAlert({
        message:
          "You're opted in to Discovery Mode! You can always click on the Intercom widget to let us know if you have any questions.",
        timeout: 20000
      })
    );
  }

  return (
    <TabbedHeaderLayout
      columns={{ default: '100%' }}
      header={{
        title: <LogoTitle />,
        customBackground: <GradientGreenBackground />,
        seo: {
          title: 'Discovery Mode',
          description: 'A dashboard to manage your Spotify Discovery Mode tracks',
          section: 'Discovery Mode'
        },
        renderTabs: () => (
          <NavTabContainer>
            <NavTabGroup>
              <NavTab
                title="Eligible Tracks"
                path={paths.eligible}
                aside={
                  <StyledBadge loading={loading}>{eligibleTracklist.length}</StyledBadge>
                }
              />
              <NavTab
                title="Enabled Tracks"
                path={paths.enabled}
                aside={
                  <StyledBadge loading={loading}>
                    {enabledTracklist.filter(track => track.enabled).length}
                  </StyledBadge>
                }
              />
              <NavTabDisabled>
                <NavTab
                  title="Insights"
                  path={paths.insights}
                  aside={<StyledBadge>COMING SOON</StyledBadge>}
                />
              </NavTabDisabled>
            </NavTabGroup>
          </NavTabContainer>
        ),
        renderHeaderActions: () => (
          <Button variant="translucent" href={settingsPaths.notificationSettings}>
            <HeaderAction>Learn More?</HeaderAction>
          </Button>
        )
      }}
      renderMain={() => {
        if (error) {
          return (
            <div className="mt-2 mb-2">
              <Banner
                icon
                variant="danger"
                compact={false}
                data-testid="DiscoveryMode-Banner-Error"
              >
                <Banner.Title>Discovery Mode Error</Banner.Title>
                <Banner.Content>
                  There was an error loading your Discovery Mode data. Please try again
                  later.
                </Banner.Content>
              </Banner>
            </div>
          );
        }

        if (loading) {
          return <TableTombstones />;
        }

        return (
          <Switch>
            <Route exact path={paths.eligible} render={() => <EligibleTracks />} />
            <Route exact path={paths.enabled} render={() => <EnabledTracks />} />
            <Redirect from={routeMap.discoveryMode} to={paths.default} />
            <Redirect from={paths.insights} to={paths.default} />
            <RouteNotFound />
          </Switch>
        );
      }}
    />
  );
};

export default DiscoveryMode;
