import React, { ComponentType, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import Modal from 'components/Modal';
import { PacmanIcon } from 'components/Icons';

import { DiscoveryModeFeatureFlagQueryQuery } from 'gql/graphql';
import { DiscoveryModeFeatureFlagQuery } from 'graphql/queries/discoveryMode';

import { OnboardingStep } from './OnboardingStep';
import { OptInSuccess } from './SuccessStep';
import { SelectEntities } from './EntitySelectionStep';
import { useQueryWithAlert } from 'hooks/graphql/useQueryWithAlert';
import { StyledModal } from '../styles';

export interface OptInProps {
  viewer: NonNullable<DiscoveryModeFeatureFlagQueryQuery['viewer']>;
  modalLocation: string;
  entityId?: string;
}

type OptInModalSteps = 'dm-opt-in' | 'success' | 'select';

const OptInModalSteps: Record<OptInModalSteps, ComponentType<OptInProps>> = {
  'dm-opt-in': OnboardingStep,
  success: OptInSuccess,
  select: SelectEntities
};

const OptInModal = () => {
  const [{ data, fetching }] = useQueryWithAlert({
    query: DiscoveryModeFeatureFlagQuery
  });
  const [CurrentStep, setCurrentStep] =
    useState<keyof typeof OptInModalSteps>('dm-opt-in');
  const location = useLocation<{ modalLocation?: string; entityId?: string }>();
  const { pathname } = useLocation();

  const hasOneArtist = data?.viewer?.artists?.length === 1;

  useEffect(() => {
    const lastPath = pathname.split('/').filter(Boolean).pop() as OptInModalSteps;
    setCurrentStep(lastPath || 'dm-opt-in');
  }, [pathname]);

  const StepComponent = OptInModalSteps[CurrentStep];

  if (fetching || !data?.viewer)
    return (
      <Modal>
        <StyledModal>
          <PacmanIcon />
        </StyledModal>
      </Modal>
    );

  return (
    <StepComponent
      viewer={data.viewer}
      modalLocation={location.state?.modalLocation || '/discovery-mode'}
      entityId={hasOneArtist ? data.viewer.artists[0].id : location.state?.entityId}
    />
  );
};

export default OptInModal;
