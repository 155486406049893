import React, { createContext, useContext, ReactNode } from 'react';
import { useQuery } from 'urql';
import useScheduledQueue from './useScheduledQueue';
import {
  DiscoveryModeStateQuery,
  EligibleTracksFragment
} from 'graphql/queries/discoveryMode';
import { DiscoveryModeQuery, EligibleTracksFieldsFragment } from 'gql/graphql';
import { getFragmentData } from 'gql';

interface DiscoveryModeContextType {
  loading: boolean;
  error?: Error;
  discoveryMode?: DiscoveryModeQuery['discoveryMode'];
  eligibleTracklist: EligibleTracksFieldsFragment[];
  enabledTracklist: EligibleTracksFieldsFragment[];
  scheduledQueue: ReturnType<typeof useScheduledQueue>;
}

const DiscoveryModeContext = createContext<DiscoveryModeContextType | undefined>(
  undefined
);

export const useDiscoveryMode = () => {
  const context = useContext(DiscoveryModeContext);
  if (!context) {
    throw new Error('useDiscoveryMode must be used within a DiscoveryModeProvider');
  }
  return context;
};

export const DiscoveryModeProvider = ({ children }: { children: ReactNode }) => {
  const [{ data, fetching: loading, error }] = useQuery({
    query: DiscoveryModeStateQuery
  });

  const discoveryMode = data?.discoveryMode;
  const eligibleTracks = data?.discoveryMode?.upcomingCampaign?.eligibleTracks;
  const eligibleTracklist = getFragmentData(EligibleTracksFragment, eligibleTracks) || [];

  const scheduledQueue = useScheduledQueue(eligibleTracklist);

  const enabledTracks = data?.discoveryMode?.runningCampaign?.eligibleTracks;
  const enabledTracklist = getFragmentData(EligibleTracksFragment, enabledTracks) || [];

  const value: DiscoveryModeContextType = React.useMemo(
    () => ({
      loading,
      error,
      discoveryMode,
      eligibleTracklist,
      enabledTracklist,
      scheduledQueue
    }),
    [loading, error, data, eligibleTracklist, enabledTracklist, scheduledQueue]
  );

  return (
    <DiscoveryModeContext.Provider value={value}>
      {children}
    </DiscoveryModeContext.Provider>
  );
};
