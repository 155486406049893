import { graphql } from "gql";

export const ToggleDiscoveryModeMutation = graphql(`
  mutation discoveryModeOptInOut($artistId: ID, $labelId: ID, $enabled: Boolean!) {
    discoveryModeOptInOut(
      input: { artistId: $artistId, labelId: $labelId, enabled: $enabled }
    ) {
      enabled
      errors
      errorDetails {
        message
        path
      }
    }
  }
`);
