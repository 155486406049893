import React, { ReactNode, useCallback } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Button, TextEmphasis } from '@protonradio/proton-ui';

import CommaList from 'components/CommaList';
import EllipsisLoader from 'components/Loader/EllipsisLoader';
import Modal from 'components/Modal';

import { getFragmentData } from 'gql/fragment-masking';
import {
  DiscoveryModeStateQuery,
  EligibleTracksFragment
} from 'graphql/queries/discoveryMode';
import { ToggleDiscoveryModeMutation } from 'graphql/mutations/discoveryMode';
import { useQueryWithAlert } from 'hooks/graphql/useQueryWithAlert';
import useMutationWithAlert from 'hooks/graphql/useMutationWithAlert';

import { StyledLink, StyledTooltipContent } from '../styles';
import { DiscoveryModeTrack } from 'gql/graphql';

const getTrackListText = (
  tracks: DiscoveryModeTrack[],
  type: 'running' | 'auto-renewing'
) => {
  if (tracks.length === 0) {
    return `You currently don't have any tracks ${type === 'running' ? 'running' : 'auto-renewing'} in Discovery Mode.`;
  }

  const baseText = `You currently have ${tracks.length} ${tracks.length === 1 ? 'track' : 'tracks'} ${
    type === 'running' ? 'running' : 'auto-renewing'
  } in Discovery Mode`;

  if (tracks.length <= 3) {
    return (
      <>
        {baseText}:{' '}
        <strong>
          <CommaList data={tracks.map(t => t.track.title)} ampersand />
        </strong>
        . <Link to="discovery-mode/enabled">Go to your dashboard.</Link>
      </>
    );
  }

  return (
    <>
      {baseText}, including:{' '}
      <strong>
        <CommaList data={tracks.slice(0, 3).map(t => t.track.title)} ampersand />
      </strong>
      .{' '}
      <Link to="discovery-mode/enabled">
        See all {type === 'auto-renewing' ? 'auto-renewing' : 'enabled'} tracks.
      </Link>
    </>
  );
};

const LoadingTooltipContent = ({
  loading,
  children
}: {
  loading: boolean;
  children: ReactNode;
}) => (
  <StyledTooltipContent>{loading ? <EllipsisLoader /> : children}</StyledTooltipContent>
);

const OptOutModal = () => {
  const [{ data, fetching: dataFetching, error: dataError }] = useQueryWithAlert({
    query: DiscoveryModeStateQuery
  });
  const [{ fetching: mutationFetching }, executeMutation] = useMutationWithAlert(
    ToggleDiscoveryModeMutation
  );
  const history = useHistory();
  const location = useLocation<{ modalLocation?: string; entityId?: string }>();
  const modalLocation = location.state.modalLocation;
  const entityId = location.state.entityId;

  const enabledTracks = data?.discoveryMode?.runningCampaign?.eligibleTracks;
  const enabledTracklist = getFragmentData(EligibleTracksFragment, enabledTracks) || [];
  const autoRenewingTracks = enabledTracklist.filter(
    t => t.intentRate && t.intentRate > 1
  );

  const goBack = () => history.push(modalLocation ?? '/account/discovery-mode');

  const handleOptOut = useCallback(async () => {
    try {
      const result = await executeMutation({ artistId: entityId, enabled: false });
      if (result.hasErrors) goBack();
    } catch (error) {
      goBack();
    } finally {
      goBack();
    }
  }, [executeMutation, entityId, modalLocation, history]);

  if (dataError || !modalLocation) {
    goBack();
    return null;
  }

  return (
    <Modal title="Opt Out of Discovery Mode?">
      <p>
        All tracks{' '}
        <TextEmphasis
          tooltipProps={{
            content: (
              <LoadingTooltipContent loading={dataFetching}>
                {getTrackListText(enabledTracklist as DiscoveryModeTrack[], 'running')}
              </LoadingTooltipContent>
            )
          }}
        >
          currently running
        </TextEmphasis>{' '}
        in Discovery Mode will stay until the end of the month.
      </p>

      <br />

      <p>
        Next month all{' '}
        <TextEmphasis
          tooltipProps={{
            content: (
              <LoadingTooltipContent loading={dataFetching}>
                <p>Tracks with an Intent Rate &gt; 1 auto-renew each month.</p>
                {getTrackListText(
                  autoRenewingTracks as DiscoveryModeTrack[],
                  'auto-renewing'
                )}
              </LoadingTooltipContent>
            )
          }}
        >
          auto-renewing
        </TextEmphasis>{' '}
        tracks will stop.
      </p>

      <Modal.ButtonGroup justify="center">
        <Button variant="secondary" isDisabled={mutationFetching}>
          <StyledLink to={modalLocation ?? '/account/discovery-mode'}>Back</StyledLink>
        </Button>

        <Button
          data-testid="OptOut-Button"
          variant="danger"
          onPress={() => handleOptOut()}
          isDisabled={mutationFetching}
        >
          {mutationFetching ? 'Opting out...' : 'Opt out'}
        </Button>
      </Modal.ButtonGroup>
    </Modal>
  );
};

export default OptOutModal;
