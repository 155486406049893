
/*
 * Returns the ordinal suffix ('st', 'nd', 'rd', or 'th') for a given number.
 * @example
 * getOrdinalSuffix(1);  // returns 'st'
 * getOrdinalSuffix(22); // returns 'nd'
 * getOrdinalSuffix(3);  // returns 'rd'
 * getOrdinalSuffix(11); // returns 'th'
 */
export const getOrdinalSuffix = (n: number): string => {
  const lastDigit = n % 10;
  const lastTwoDigits = n % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
    return 'th';
  }

  switch (lastDigit) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

/**
 * Formats a date into a string with the format: "Weekday, Month Day(st/nd/rd/th)".
 * @example
 * formatDate(new Date('2023-05-15')); // returns "Monday, May 15th"
 * formatDate('2023-12-22');           // returns "Friday, December 22nd"
 */
export const formatDate = (date: Date | string): string => {
  const dateObj = new Date(date);
  const formatter = new Intl.DateTimeFormat('en-US', {
    timeZone: 'UTC',
    weekday: 'long',
    month: 'long',
    day: 'numeric'
  });
  const formattedDate = formatter.format(dateObj);
  const day = dateObj.getUTCDate();
  const suffix = getOrdinalSuffix(day);
  return formattedDate.replace(/\d+$/, `${day}${suffix}`);
};

