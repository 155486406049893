import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import ActionMenu, { ActionMenuToggleState } from 'components/ActionMenu/ActionMenu';
import ActionMenuItem from 'components/ActionMenu/ActionMenuItem';
import ActionMenuList from 'components/ActionMenu/ActionMenuList';

import TextHeader from 'components/TextHeader';
import { COLORS, AUDIO_FILE_TYPES } from 'config/constants';
import { generateSelectorsFromTestIds } from 'helpers';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { canDownloadLossless } from '../../../helpers/tracks';
import { useAppSelector } from 'hooks/redux';
import { selectUser } from 'redux/selectors/user';
import { Audio } from 'helpers/tracks';
import { DownloadLocationOption } from 'helpers/downloadLocation';

const StyledTextHeader = styled(TextHeader)`
  margin: 0;
  padding: 1.5rem 1.5rem 0 1.5rem;
`;

const Divider = styled.div`
  background: #ddd;
  height: 0.1rem;
  width: 100%;
`;

const TextHighlighter = styled.span<{ $highlighted: boolean; $disabled?: boolean }>`
  ${({ $highlighted }) =>
    $highlighted &&
    `
    font-weight: 500;
    color: ${COLORS.PRIMARY};
  `}
  ${({ $disabled }) =>
    $disabled &&
    `
    color: ${COLORS.GRAY_LIGHT};
    text-decoration: line-through;
  `}
`;

interface FilePickerActionMenuProps extends ActionMenuToggleState {
  track: Audio;
  downloadLocationOptions: DownloadLocationOption[];
  currentLocationOption: DownloadLocationOption;
  handleSettingsUpdate: (settingType: string, value?: string) => void;
}

const FilePickerActionMenu = ({
  track,
  downloadLocationOptions,
  currentLocationOption,
  handleSettingsUpdate,
  isOpen,
  close,
  closeOnNavigation
}: FilePickerActionMenuProps) => {
  const { user: currentUser } = useCurrentUser();
  const user = useAppSelector(selectUser);
  const hasPremiumDownloads = canDownloadLossless(currentUser, { user, track });
  const preferredAudioDownloadType = currentUser.settings['DOWNLOAD_FORMAT'].value;

  return (
    <ActionMenu
      isOpen={isOpen}
      close={close}
      closeOnNavigation={closeOnNavigation}
      testId={FilePickerActionMenu.TEST_IDS.CONTAINER}
      showIntercomOnClose={false}
    >
      <StyledTextHeader type={TextHeader.TYPES.LARGE} weight={TextHeader.WEIGHTS.HEAVY}>
        File Type
      </StyledTextHeader>
      <ActionMenuList>
        {Object.values(AUDIO_FILE_TYPES).map(({ LABEL, VALUE, PREMIUM }) => {
          const isDisabled = !hasPremiumDownloads && PREMIUM;
          const isHighlighted = !hasPremiumDownloads
            ? VALUE === 'mp3'
            : preferredAudioDownloadType === VALUE;

          return (
            <ActionMenuItem
              key={`DownloadTypes:${VALUE}`}
              testId={FilePickerActionMenu.TEST_IDS.getTestIdForFileType(VALUE)}
              disabled={isDisabled}
              onClick={() => {
                handleSettingsUpdate('DOWNLOAD_FORMAT', VALUE);
                setTimeout(() => close(), 300);
              }}
              closeMenu={close}
            >
              <TextHighlighter $highlighted={isHighlighted} $disabled={isDisabled}>
                {LABEL}
              </TextHighlighter>
            </ActionMenuItem>
          );
        })}
      </ActionMenuList>

      <Divider />

      <StyledTextHeader type={TextHeader.TYPES.LARGE}>Download Location</StyledTextHeader>
      <ActionMenuList>
        {downloadLocationOptions.map(({ label, value, key }) => {
          return (
            <ActionMenuItem
              key={`DownloadLocations:${value || key}`}
              testId={FilePickerActionMenu.TEST_IDS.getTestIdForDownloadType(label)}
              onClick={() => {
                handleSettingsUpdate('DOWNLOAD_LOCATION', value);
                // Short delay so users see their selection get highlighted before closing
                setTimeout(() => close(), 300);
              }}
              closeMenu={close}
            >
              <TextHighlighter $highlighted={currentLocationOption.value === value}>
                {label}
              </TextHighlighter>
            </ActionMenuItem>
          );
        })}
      </ActionMenuList>
    </ActionMenu>
  );
};

const getTestIdForFileType = value =>
  `FilePickerActionMenu-fileType-${_.kebabCase(value)}`;
const getTestIdForDownloadType = value =>
  `FilePickerActionMenu-downloadType-${_.kebabCase(value)}`;

FilePickerActionMenu.TEST_IDS = {
  CONTAINER: 'FilePickerActionMenu',
  WAV: getTestIdForFileType('wav'),
  MP3: getTestIdForFileType('mp3'),
  AIFF: getTestIdForFileType('aiff'),
  getTestIdForFileType,
  DROPBOX: getTestIdForDownloadType('dropbox'),
  DIRECT_DOWNLOAD: getTestIdForDownloadType('direct-download'),
  getTestIdForDownloadType
};

FilePickerActionMenu.SELECTORS = generateSelectorsFromTestIds(
  FilePickerActionMenu.TEST_IDS
);

export default FilePickerActionMenu;
