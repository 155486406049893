import { useEffect } from 'react';
import { AnyVariables, UseQueryArgs, UseQueryResponse, useQuery } from 'urql';

import { isProduction, isTesting } from 'config/constants';

import { logError } from 'helpers/logError';
import { showAlert } from 'redux/actions/ui';
import { useAppDispatch } from '../redux';
import useCurrentUser from '../useCurrentUser';
import { isProUser, isSkipped, unexpectedErrorMessage } from './utils';

/**
 * Wraps the URQL `useQuery` hook to handle errors by dumping them into a
 * built-in alert. On production, the actual error message is squashed and
 * replaced by a generic user-friendly message.
 *
 * Accepts a `skip` object as an optional second parameter with key-value pairs indicating
 * specific error case(s) handled by the calling code, matching errors will not produce alerts.
 * For any key-value pair passed in this way, the key can match any member of the error's `path`
 * array, and the value is matched against the error's `message`.
 *
 * @remarks
 * Both "top-level" errors (see {@link https://formidable.com/open-source/urql/docs/basics/errors/ urql CombinedError})
 * and errors nested within the response data (arrays of {@link GqlApiError}) produce alerts but neither
 * will reject the Promise, since errors may co-exist with valid data.
 *
 * If an API error should result in a Promise rejection it is up to the caller to reject.
 */
export const useQueryWithAlert = <
  Data = any,
  Variables extends AnyVariables = AnyVariables
>(
  args: UseQueryArgs<Variables, Data>,
  skip?: { [key: string]: string }
): UseQueryResponse<Data, Variables> => {
  const [queryState, executeQuery] = useQuery<Data, Variables>(args);
  const dispatch = useAppDispatch();
  const { user: currentUser } = useCurrentUser();

  const { error } = queryState;
  useEffect(() => {
    if (error) {
      const errors = error.graphQLErrors;
      const skipAll = !skip ? false : errors && errors.every(e => isSkipped(e, skip));
      if ((isProduction || isTesting) && !skipAll) {
        dispatch(
          showAlert({
            message: unexpectedErrorMessage(isProUser(currentUser), errors),
            error: 'API error'
          })
        );
      } else {
        if (!skipAll) logError(errors, skip ? { metadata: { ignore: skip } } : undefined);
        for (const e of errors) {
          if (!isSkipped(e, skip)) dispatch(showAlert({ error: e }));
        }
      }
    }
  }, [currentUser, dispatch, error, skip]);

  return [queryState, executeQuery];
};
