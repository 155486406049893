import TextHeader from 'components/TextHeader';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledModal = styled.div`
  width: 100%;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const StyledTooltipContent = styled.div`
  max-width: 300px;
  line-height: 1.8rem;
  padding: 0.4rem 0.8rem;

  p {
    margin-bottom: 0.4rem;
  }
`;

export const StyledTextHeader = styled(TextHeader)`
  margin-bottom: 0;
`;

export const StyledLink = styled(Link)`
  color: inherit;
  padding: 0.4rem 0.8rem;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
`;
