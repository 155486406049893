import React, { ReactNode, useEffect } from 'react';
import { Keys } from 'react-keydown';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { COLORS, BREAKPOINTS, Z_INDEX } from 'config/constants';
import { generateSelectorsFromTestIds } from 'helpers/utilities';
import useBreakpoint from 'hooks/useBreakpoint';
import useLockBodyScroll from 'hooks/useLockBodyScroll';
import useOnKeyPress from 'hooks/useOnKeyPress';
import protonLogo from 'images/proton-logo.svg';
import CloseIcon from 'images/icon-close--reverse.svg';
import CloseIconDark from 'images/icon-close--reverse-black.svg';
import { ButtonGroup } from 'components/Button';
import { hideIntercomLauncher, showIntercomLauncher } from 'config/intercom';

const TEST_IDS = {
  CONTAINER: 'Modal-container',
  BODY: 'Modal-body',
  CLOSE_ICON: 'Modal-close-icon',
  ERROR_MESSAGE: 'modal-error-message'
};

interface ModalProps {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  enableBanner?: boolean;
  'data-testid'?: string;
  bannerFooter?: React.ReactNode;
  frozen?: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
  errorMessage?: React.ReactNode;
  testId?: string;
}

// You can conditionally only apply the banner on desktop or mobile
interface DesktopModalProps extends ModalProps {
  onClose: () => void;
  enableDesktopBanner?: boolean;
}

interface MobileModalProps extends ModalProps {
  onClose: () => void;
  enableMobileBanner?: boolean;
}

const Modal = ({ onClose, frozen, bannerFooter = null, ...props }: ModalProps) => {
  useLockBodyScroll();

  useEffect(() => hideIntercomLauncher());

  const history = useHistory();
  const handleClose = onClose
    ? () => {
        setTimeout(() => showIntercomLauncher(), 200);
        if (frozen) return;
        onClose();
      }
    : () => {
        setTimeout(() => showIntercomLauncher(), 200);
        if (frozen) return;
        history.goBack();
      };

  useOnKeyPress(Keys.ESC, handleClose, { keyActionDirection: 'keydown' });

  // There are still quite a few media queries in the CSS.
  const isSmallScreen = useBreakpoint(
    useBreakpoint.BREAKPOINTS.SMALL,
    useBreakpoint.DIRECTIONS.DOWN
  );

  const modalProps = {
    ...props,
    frozen,
    bannerFooter,
    onClose: handleClose
  };

  return isSmallScreen ? (
    <Modal_Mobile {...modalProps} />
  ) : (
    <Modal_Desktop {...modalProps} />
  );
};

const Modal_Desktop = ({
  title,
  subtitle,
  bannerFooter,
  enableBanner,
  enableDesktopBanner,
  frozen,
  onClose,
  children,
  errorMessage,
  testId = TEST_IDS.CONTAINER
}: DesktopModalProps) => {
  const showBanner = enableBanner || enableDesktopBanner;

  return (
    <Modal.Container $showBanner={showBanner} onClick={onClose} data-testid={testId}>
      <Modal_Dialog onClick={e => e.stopPropagation()}>
        <Modal_Content $enableBanner={showBanner}>
          {errorMessage && (
            <Modal_ErrorMessage data-testid={TEST_IDS.ERROR_MESSAGE}>
              {errorMessage}
            </Modal_ErrorMessage>
          )}
          <div style={{ padding: 32 }}>
            {title && <Modal_Title>{title}</Modal_Title>}
            {subtitle && <Modal_Subtitle>{subtitle}</Modal_Subtitle>}
            {children}
          </div>
        </Modal_Content>

        {showBanner && <Modal.Banner>{bannerFooter}</Modal.Banner>}

        {!frozen && <Modal_DismissButton darkMode={showBanner} onClose={onClose} />}
      </Modal_Dialog>
      <Modal_Background />
    </Modal.Container>
  );
};

const Modal_Mobile = ({
  title,
  subtitle,
  bannerFooter,
  frozen,
  onClose,
  children,
  errorMessage,
  enableBanner,
  enableMobileBanner,
  testId = TEST_IDS.CONTAINER
}: MobileModalProps) => {
  const showBanner = enableMobileBanner || enableBanner;

  return (
    <Modal.Container $showBanner={showBanner} onClick={onClose} data-testid={testId}>
      <Modal_Dialog onClick={e => e.stopPropagation()}>
        {errorMessage && <Modal_ErrorMessage>{errorMessage}</Modal_ErrorMessage>}
        <div style={{ position: 'relative', width: '100%' }}>
          {showBanner && <Modal.MobileBanner />}

          {!frozen && <Modal_DismissButton darkMode={showBanner} onClose={onClose} />}
        </div>
        <Modal_Content>
          <div style={{ padding: 32 }}>
            {!errorMessage && title && (
              <Modal_Title $darkMode={showBanner}>{title}</Modal_Title>
            )}
            {subtitle && (
              <Modal_Subtitle $darkMode={showBanner}>{subtitle}</Modal_Subtitle>
            )}
            {children}
          </div>
        </Modal_Content>
        {bannerFooter}
      </Modal_Dialog>

      <Modal_MobileBackground $useImage={showBanner} />
      {showBanner && <Modal_Background />}
    </Modal.Container>
  );
};

const Modal_ErrorMessage = styled.div`
  background-color: #e33939;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  line-height: 18px;
  padding: 16px;
  color: white;
  position: relative;
  font-size: 16px;

  @media screen and (max-width: ${BREAKPOINTS.SMALL}px) {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    width: 100%;
    top: 22px;
  }

  & a {
    color: white;
    text-decoration: underline;
  }

  & a:hover {
    text-decoration: none;
    cursor: pointer;
  }
`;

const Modal_DismissContainer = styled.div`
  position: absolute;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  right: 0px;
  opacity: 0.7;
  transition: opacity 0.5s;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }

  @media screen and (max-width: ${BREAKPOINTS.SMALL}px) {
    position: absolute;
    top: 20px;
    right: 0px;
  }
`;

const Modal_DismissIcon = styled.img`
  position: relative;
  height: 30px;
`;

const Modal_DismissButton = ({
  onClose,
  darkMode
}: {
  onClose: () => void;
  darkMode?: boolean;
}) => (
  <Modal_DismissContainer data-testid={TEST_IDS.CLOSE_ICON} onClick={onClose}>
    <Modal_DismissIcon src={darkMode ? CloseIcon : CloseIconDark} />
  </Modal_DismissContainer>
);

const Modal_BannerMessage = styled.div`
  font-size: 16px;
  color: #fff;
  padding: 12px 0;
  text-align: center;
  flex: 0;
`;

const Modal_BannerAction = styled(Link)`
  height: 32px;
  border-radius: 40px;
  background-color: rgba(255, 255, 255, 0.15);
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  padding: 4px 24px;
  text-align: center;
  border: 2px solid rgba(0, 0, 0, 0);
  text-decoration: none;
  flex: 0;

  &:hover {
    color: #fff;
    border-color: #fff;
    background-color: rgba(255, 255, 255, 0.3);
    cursor: pointer;
    transition:
      background-color 0.5s,
      border-color 0.5s;
    text-decoration: none;
  }

  @media screen and (max-width: ${BREAKPOINTS.SMALL}px) {
    margin-bottom: auto;
  }
`;

interface BannerFooterProps {
  message: string;
  action: string;
  actionURL: string;
}

const Modal_BannerFooter = ({ message, action, actionURL }: BannerFooterProps) => (
  <>
    <Modal_BannerMessage>{message}</Modal_BannerMessage>
    <Modal_BannerAction
      to={{
        pathname: actionURL,
        state: { modal: true }
      }}
    >
      {action}
    </Modal_BannerAction>
  </>
);

const Modal_Background = styled.div`
  background: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(6px);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 7;

  @media screen and (max-width: ${BREAKPOINTS.SMALL}px) {
    background: rgba(0, 0, 0, 0.35);
    height: 110%;
  }
`;

const Modal_MobileBackground = styled.div<{ $useImage?: boolean }>`
  background-image: ${({ $useImage }) =>
    $useImage ? "url('/images/promo-decks.jpg')" : ''};
  background-color: white;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 85% 50%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 7;

  @media screen and (max-width: ${BREAKPOINTS.SMALL}px) {
    height: 110%;
  }
`;

const Modal_Container = styled.div<{ $showBanner?: boolean; 'data-testid': string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 54px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${Z_INDEX.MODAL_DESKTOP};
  overflow: hidden;

  @media screen and (max-width: ${BREAKPOINTS.LARGE}px) {
    top: 44px;
  }

  @media screen and (max-width: ${BREAKPOINTS.SMALL}px) {
    overflow-y: scroll;
    top: 0px;
    z-index: ${Z_INDEX.MODAL_MOBILE};

    ${({ $showBanner }) =>
      $showBanner &&
      `
      color: white;
    `}
  }
`;

Modal_Container.defaultProps = {
  'data-testid': TEST_IDS.CONTAINER
};

export const Modal_Dialog = styled.div`
  width: 650px;
  z-index: 8;
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  top: -27px;

  @media screen and (max-width: ${BREAKPOINTS.LARGE}px) {
    top: -22px;
  }

  @media screen and (max-width: ${BREAKPOINTS.MEDIUM}px) {
    max-width: 600px;
  }

  @media screen and (max-width: ${BREAKPOINTS.SMALL}px) {
    width: 100%;
    height: 100%;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
  }
`;

export const Modal_Content = styled.div<{
  $enableBanner?: boolean;
  'data-testid'?: string;
}>`
  border-radius: 8px;
  flex: 1 0 425px;
  background: ${COLORS.WHITE};
  position: relative;

  @media screen and (max-width: ${BREAKPOINTS.MEDIUM}px) {
    flex: ${({ $enableBanner }) => ($enableBanner ? '0 0 100%' : '0 0 380px')};
  }

  @media screen and (max-width: ${BREAKPOINTS.SMALL}px) {
    background: none;
    width: 100%;
    border-radius: 0;
    flex: 0;
    margin-bottom: auto;
    margin-top: auto;
  }
`;

Modal_Content.defaultProps = {
  'data-testid': TEST_IDS.BODY
};

export const Modal_BannerContainer = styled.div`
  flex: 1 0 200px;
  background-image: url('/images/promo-decks.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 55%;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;

  @media screen and (max-width: ${BREAKPOINTS.MEDIUM}px) {
    flex: 1 0 200px;
  }
`;

const Modal_Title = styled.h1<{ $darkMode?: boolean; 'data-testid'?: string }>`
  font-size: 3rem;
  font-weight: 900;
  color: #000;
  margin-bottom: 1.4rem;

  @media screen and (max-width: ${BREAKPOINTS.SMALL}px) {
    color: ${({ $darkMode }) => ($darkMode ? '#fff' : '#000')};
    font-size: 24px;
    text-align: center;
  }
`;

Modal_Title.defaultProps = {
  'data-testid': 'Modal_Title'
};

export const Modal_Subtitle = styled.div<{ $darkMode?: boolean }>`
  font-size: 16px;
  color: #7d7d7d;
  line-height: 22px;

  @media screen and (max-width: ${BREAKPOINTS.SMALL}px) {
    color: ${({ $darkMode }) => ($darkMode ? '#fff' : '#000')};
    line-height: 18px;
    text-align: center;
  }
`;

const Modal_LogoContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  padding-top: 48px;
`;

const Modal_Logo = styled.img`
  width: 130px;
  height: 40px;
  margin: 8px 0;
`;

const Modal_LogoSince = styled.div`
  font-size: 14px;
  letter-spacing: 2px;
  color: #fff;
`;

const Modal_SignInContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  margin-bottom: 54px;
`;

const Modal_PolicyMessage = styled.div`
  font-size: 12px;
  text-align: center;
  width: 100%;
  padding-top: 12px;
  line-height: 14px;
`;

export const Modal_MobileBanner = styled.div`
  position: relative;
  width: 100%;
  height: 175px;
  flex: 0 0 175px;

  @media screen and (max-width: ${BREAKPOINTS.SMALL}px) {
    padding-top: 16px;
    height: auto;
    flex: 0 1 auto;
    margin-bottom: auto;
    margin-top: auto;
  }
`;

export const Modal_Text = styled.div`
  font-size: 16px;
  margin-top: 16px;
  line-height: 22px;

  @media screen and (max-width: ${BREAKPOINTS.SMALL}px) {
    text-align: center;
  }
`;

const Modal_Banner = ({ children }: { children: React.ReactNode }) => (
  <Modal_BannerContainer>
    <Modal_LogoContainer>
      <Modal_Logo src={protonLogo} />
      <Modal_LogoSince>SINCE 2001</Modal_LogoSince>
    </Modal_LogoContainer>
    <Modal_SignInContainer>{children}</Modal_SignInContainer>
  </Modal_BannerContainer>
);

const Modal_MobileHeader = () => (
  <Modal_MobileBanner>
    <Modal_LogoContainer>
      <Modal_Logo src={protonLogo} />
      <Modal_LogoSince>SINCE 2001</Modal_LogoSince>
    </Modal_LogoContainer>
  </Modal_MobileBanner>
);

const Modal_ButtonGroup = ({
  children,
  justify
}: {
  children: ReactNode;
  justify?: string;
}) => {
  const isSmallScreen = useBreakpoint(
    useBreakpoint.BREAKPOINTS.SM,
    useBreakpoint.DIRECTIONS.DOWN
  );

  if (isSmallScreen) justify = 'center';

  return (
    <ButtonGroup justify={justify} className="mt-3">
      {children}
    </ButtonGroup>
  );
};

Modal.Container = Modal_Container;
Modal.Banner = Modal_Banner;
Modal.MobileBanner = Modal_MobileHeader;
Modal.PolicyMessage = Modal_PolicyMessage;
Modal.BannerFooter = Modal_BannerFooter;
Modal.Text = Modal_Text;
Modal.Subtitle = Modal_Subtitle;
Modal.ButtonGroup = Modal_ButtonGroup;
Modal.TEST_IDS = TEST_IDS;

export default Modal;
